import React from 'react'

const Otp = ({otp}) => {
    return (
        <div style={{display: 'flex',  justifyContent:'center', alignItems:'center', height: '100vh'}}>
            <h1>{otp}</h1>
        </div>
    )
};

export default Otp
