import Otp from './components/otp';
import React, {useState, useEffect} from 'react';
import env from "react-dotenv";

const App = () => {
    const [otp, setOtp] = useState(null)

    useEffect(() => {
        fetch(`${env.BACKEND_URL}/otp`)
            .then(response => response.json())
            .then(responseBody => setOtp(responseBody.otp))
            .catch(() => setOtp("not found"))
    }, [])

    return (
        <Otp otp={otp}/>
    );
};

export default App;
